<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="PayPal"
        role="img"
        viewBox="0 0 512 512"
        style="width: 24px; height: 24px"
    >
        <rect width="512" height="512" rx="15%" fill="#fff" />
        <path
            fill="#002c8a"
            d="M377 184.8L180.7 399h-72c-5 0-9-5-8-10l48-304c1-7 7-12 14-12h122c84 3 107 46 92 112z"
        />
        <path
            fill="#009be1"
            d="M380.2 165c30 16 37 46 27 86-13 59-52 84-109 85l-16 1c-6 0-10 4-11 10l-13 79c-1 7-7 12-14 12h-60c-5 0-9-5-8-10l22-143c1-5 182-120 182-120z"
        />
        <path
            fill="#001f6b"
            d="M197 292l20-127a14 14 0 0 1 13-11h96c23 0 40 4 54 11-5 44-26 115-128 117h-44c-5 0-10 4-11 10z"
        />
    </svg>
</template>

<script>
export default {
    name: "PaypalIcon",
};
</script>
