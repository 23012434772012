<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Patreon"
        role="img"
        viewBox="0 0 512 512"
        style="width: 24px; height: 24px"
    >
        <rect width="512" height="512" rx="15%" fill="#052d49" />
        <circle cx="310" cy="223" r="113" fill="#fff" />
        <path fill="#f96854" d="M165 410V110h-56v300" />
    </svg>
</template>

<script>
export default {
    name: "PatreonIcon",
};
</script>
