<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Liberapay"
        role="img"
        viewBox="0 0 512 512"
        style="width: 24px; height: 24px"
    >
        <rect width="512" height="512" rx="15%" fill="#f6c915" />
        <path
            fill="#fff"
            d="M209 359c-17 0-30-2-39-7-10-4-16-10-20-17-4-8-6-16-6-26s1-20 4-31l44-185 54-8-48 201-2 11c0 4 0 7 2 9 1 3 4 5 7 7l14 3-10 43M404 243c0 17-3 33-9 47a103 103 0 0 1-59 60 117 117 0 0 1-68 7l-16 63h-51l57-241a305 305 0 0 1 71-11c13 0 24 2 34 6a63 63 0 0 1 36 40c3 9 5 19 5 29m-127 72l15 1c9 0 17-1 25-5 7-3 13-8 19-14 5-6 9-13 12-22 3-8 4-18 4-28s-2-18-6-25c-5-7-13-11-23-11l-21 2-25 102"
        />
    </svg>
</template>

<script>
export default {
    name: "LiberapayIcon",
};
</script>
